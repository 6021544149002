/* eslint-disable import/prefer-default-export */

export const bulkUploadFormMixin = {
  data() {
    return {
    }
  },

  methods: {
    loadBulkJobForms() {
      let bulkJobForms = localStorage.getItem('bulkJobForms') || '{}'
      bulkJobForms = JSON.parse(bulkJobForms)
      localStorage.setItem('bulkJobFormsCount', this.$length(bulkJobForms))
      return bulkJobForms
    },

    updateBulkJobForms(bulkJobForms) {
      localStorage.setItem('bulkJobForms', JSON.stringify(bulkJobForms))
      localStorage.setItem('bulkJobFormsCount', this.$length(bulkJobForms))
    },

    addBulkJobForm(module) {
      const bulkJobForms = this.loadBulkJobForms()
      const target = { position: this.formData.position, company_id: this.formData.company_id }

      function comparePositions(target, jobDetails) {
        // Check if jobDetails or jobDetails.ATS is empty or undefined
        if (!jobDetails || !jobDetails.ATS) {
          return []
        }

        // Filter positions that match the target position
        const positions = Object.values(jobDetails.ATS).filter(job => job.position === target.position)
        return positions
      }
      
      const matchingPositions = comparePositions(target, bulkJobForms)

      if (matchingPositions.length === 0) {
        try {
          delete this.formData.local_storage_id
        } catch (_) {
        }
      }

      const jobToAdd = { ...this.formData }
      if (!('local_storage_id' in jobToAdd)) {
        let localStorageId = `ls-${this.$generateUUID4()}`
        if (jobToAdd.job_id) {
          localStorageId = `ls-${jobToAdd.job_id}`
        }
        jobToAdd.local_storage_id = localStorageId
      }
      if (this.$isEmpty(bulkJobForms[module])) {
        bulkJobForms[module] = {}
      }

      jobToAdd.retrievedFormData = this.retrievedFormData
      jobToAdd.publication_status = jobToAdd.event_status === 'draft' ? 'draft' : null
      bulkJobForms[module][jobToAdd.local_storage_id] = jobToAdd
      jobToAdd.record_action_options = []
      if (['applicant-tracking-system-edit'].includes(this.$route.name)) {
        jobToAdd.event_id = null //* ATS will not have event_id
        if (jobToAdd.source_db === 'hhq') {
          jobToAdd.record_action = 'add_job'
          jobToAdd.record_action_options.push({ value: 'add_job', text: 'Add Job' })
        } else {
          jobToAdd.record_action = 'edit_this'
          jobToAdd.record_action_options.push({ value: 'edit_this', text: 'Edit Job' })
        }
      } else if (this.$isNotEmpty(jobToAdd.event_ids) && jobToAdd.event_ids.includes(this.eventId)) {
        jobToAdd.record_action = 'update_event_id'
        jobToAdd.record_action_options.push({ value: 'update_event_id', text: 'Add Job to Event' })
      } else if (jobToAdd.job_id && jobToAdd.from_source !== 'mcf_jobs') {
        jobToAdd.record_action = 'edit_this'
        jobToAdd.record_action_options.push({ value: 'edit_this', text: 'Add Job to Event' })
        jobToAdd.record_action_options.push({ value: 'edit_all', text: 'Add & edit this Job on all Events' })
      }

      localStorage.setItem('bulkJobForms', JSON.stringify(bulkJobForms))
      localStorage.setItem('bulkJobFormsCount', this.$length(bulkJobForms))
      this.resetForm(['interview_settings'])
      jobToAdd.post_channel.map(item => { this.optional.destination[item] = true })

      localStorage.setItem(`${module}_post_channel`, JSON.stringify(jobToAdd.post_channel))
    },

    deleteBulkJobForm(module, index) {
      const bulkJobForms = this.loadBulkJobForms()

      // bulkJobForms[module].splice(index, 1);
      delete bulkJobForms[module][index]

      localStorage.setItem('bulkJobForms', JSON.stringify(bulkJobForms))
      localStorage.setItem(`${module}_post_channel`, JSON.stringify(null))
      this.bulkJobForms = bulkJobForms
    },

    clearBulkJobForm(module) {
      // const bulkJobForms = this.loadBulkJobForms()
      localStorage.removeItem('bulkJobFormsCount')
      localStorage.removeItem('bulkJobForms')
      localStorage.removeItem(`${module}_post_channel`)

      // bulkJobForms[module].splice(index, 1);
      // if (module in bulkJobForms) {
      //   delete bulkJobForms[module]
      // }

      // localStorage.setItem('bulkJobForms', JSON.stringify(bulkJobForms))
    },
  },
}
